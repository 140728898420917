.Updates {
    width: 85%;
    background-color: white;
    border-radius: 0.7rem;
    -webkit-border-radius: 0.7rem;
    -moz-border-radius: 0.7rem;
    -ms-border-radius: 0.7rem;
    -o-border-radius: 0.7rem;
    padding: 1rem;
    gap: 1rem;
    display: flex;
    flex-direction: column;
    font-size: 13px;
}

.update {
    display: flex;
    gap: 0.5rem;
}

.update img {
    width: 3.2rem;
    height: 3.2rem;
}

.noti > div > span:nth-of-type(1) {
    font-weight: bold;
}