.CompactCard {
    display: flex;
    flex: 1;
    height: 7rem !important;
    border-radius: 0.7rem;
    -webkit-border-radius: 0.7rem;
    -moz-border-radius: 0.7rem;
    -ms-border-radius: 0.7rem;
    -o-border-radius: 0.7rem;
    color: white;
    position: relative;
    padding: 1rem;
    cursor: pointer;
}

.CompactCard:hover {
    box-shadow: none !important;
}

.radialBar {
    flex: 1 1;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    gap: 1rem;
}

.CircularProgressbar {
    width: 4rem !important;
    overflow: visible;
}

.CircularProgressbar-path {
    stroke: white !important;
    stroke-width: 12px !important;
    filter: drop-shadow(2px 4px 6px white);
    -webkit-filter: drop-shadow(2px 4px 6px white);
}

.CircularProgressbar-text {
    fill: white !important;
}

.CircularProgressbar-trail {
    display: none;
}

.radialBar > span {
    font-size: 17px;
    font-weight: bold;
}

/* details */
.detail {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
}

.detail > span:nth-child(2) {
    font-size: 22px;
    font-weight: bold;
}

.detail > span:nth-child(3) {
    font-size: 12px;
}

/* expandableCard */
.ExpandedCard {
    position: absolute;
    width: 60%;
    height: 70vh;
    z-index: 9;
    border-radius: 1rem;
    left: 13rem;
    display: flex;
    flood-color: aliceblue;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -ms-border-radius: 1rem;
    -o-border-radius: 1rem;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    flex-direction: column;
}

.ExpandedCard > span:nth-of-type(1) {
    color: white;
    font-size: 26px;
    font-weight: bold;
    text-shadow: 0px 0px 15px white;
}

.ExpandedCard > span:nth-of-type(2) {
    color: rgb(223, 222, 222);
    font-size: 16px;
}

.chartContainer {
    width: 70%;
}

@media screen and (max-width:1200px) {
    .ExpandedCard {
        top: 2rem;
        height: 70vh;
        left: 6rem;
    }
}

@media screen and (max-width:768px) {
    .ExpandedCard {
        top: 8rem;
        height: 50%;
        left: 15px;
        width: 80%;
    }
}